<template>
  <div>
    <v-card>
      <v-toolbar style="margin-bottom: 5px" flat>
        <v-toolbar-title>
          <span class="headline"> Categoria </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose($event)">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />
      <v-row v-if="loading" class="justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-col v-else cols="12" md="12" class="my-3 px-4 d-flex">
        <div v-if="hasArea" style="width: 20%" class="pt-4">
          <div class="d-flex flex-wrap justify-space-between">
            <label
              v-if="!mapaUrl"
              for="mapa"
              style="cursor: pointer; width: 100%"
              class="caption d-flex justify-center flex-wrap border mr-4 px-2"
              title="Add mapa cliente"
            >
              <v-icon
                x-large
                color="primary"
                style="height: 200px; width: 100%"
                for="mapa"
                >mdi-cloud-upload</v-icon
              >
              <span class="mb-2"> Mapa do Evento </span>
              <input
                id="mapa"
                style="display: none"
                type="file"
                @change="handleUpload('mapa', $event)"
              />
            </label>
            <v-img
              v-else
              max-height="250"
              style="width: 15%"
              class="caption d-flex justify-center flex-wrap mr-4 px-2"
              :src="mapaUrl"
            >
            </v-img>
            <div
              v-if="mapaUrl"
              style="cursor: pointer"
              class="w-full d-flex justify-end pr-2 mt-2 hover-red"
              :disabled="checkDate"
              @click="deleteMapa()"
            >
              <v-icon> mdi-delete </v-icon>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <v-row class="d-flex px-2">
            <v-alert
              v-if="edit"
              dense
              outlined
              type="warning"
              width="100%"
              dark
            >
              Editando Ingresso - {{ especificacao }}
            </v-alert>
            <v-select
              v-if="hasLote"
              v-model="lote"
              :items="lotes"
              :disabled="situacao == 3 || situacao == 4"
              label="Lote"
              class="pr-2 w-8"
            />
            <v-select
              v-if="hasArea"
              v-model="area"
              :disabled="situacao == 3 || situacao == 4"
              :items="areas"
              label="Área"
              class="pr-2 w-8"
            />

            <vuetify-money
              v-model="valorInicial"
              :disabled="situacao == 3 || situacao == 4"
              label="Preço da Entrada"
              class="pr-2 w-12"
            />
            <vuetify-money
              v-model="valorDesconto"
              :disabled="situacao == 3 || situacao == 4"
              label="Desconto"
              class="pr-2 w-12"
            />
            <vuetify-money
              v-model="taxa"
              label="Taxa"
              class="pr-4 w-8"
              disabled
            />
            <v-text-field
              v-model="quantidade"
              v-mask="['#####']"
              :disabled="situacao == 3 || situacao == 4"
              label="Quantidade"
              type="number"
              class="pr-2 w-10"
            />
            <v-text-field
              v-model="especificacao"
              :disabled="situacao == 3 || situacao == 4"
              label="Especificação"
              class="pr-2 w-15"
            />
            <v-btn
              v-if="!edit"
              color="primary"
              fab
              :disabled="situacao == 3 || situacao == 4 || checkDate"
              small
              @click="salvarIngresso()"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              v-if="edit"
              color="primary"
              fab
              class="ml-2"
              :disabled="
                situacao == 3 || situacao == 4 || checkQuantidade || checkDate
              "
              small
              @click="salvarIngresso()"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              v-if="edit"
              color="primary"
              fab
              class="ml-2"
              :disabled="situacao == 3 || situacao == 4 || checkDate"
              small
              @click="clean()"
            >
              <v-icon> mdi-broom </v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="d-flex flex-wrap">
            <h2 class="color-detail">Itens</h2>
          </div>
          <div
            v-for="ingresso in ingressos"
            :key="ingresso.ingressoDescricao"
            class="my-2"
          >
            <div class="w-full d-flex justify-space-between pl-4">
              <div v-if="hasLote" class="d-flex flex-wrap w-8">
                <span class="text-caption color-detail"> Lote: </span>
                <span class="w-full"> {{ ingresso.loteId }} </span>
              </div>
              <div v-if="hasArea" class="d-flex flex-wrap w-8">
                <span class="text-caption color-detail"> Área: </span>
                <span class="w-full"> {{ ingresso.areaId }} </span>
              </div>
              <div class="d-flex flex-wrap w-10">
                <span class="text-caption color-detail"> Especificação: </span>
                <span class="w-full">
                  {{ ingresso.ingressoDescricao }}
                </span>
              </div>
              <div class="d-flex flex-wrap w-10">
                <span class="text-caption color-detail"> Preço Entrada: </span>
                <span class="w-full">
                  R${{ asMoney(ingresso.valorTotal) }}
                </span>
              </div>
              <div class="d-flex flex-wrap w-10">
                <span class="text-caption color-detail"> Desconto: </span>
                <span class="w-full">
                  R${{ asMoney(ingresso.valorDesconto) }}
                </span>
              </div>
              <div class="d-flex flex-wrap w-8">
                <span class="text-caption color-detail"> Taxa: </span>
                <span class="w-full">
                  R$ {{ asMoney(ingresso.valorTaxaConveniencia) }}</span
                >
              </div>
              <div class="d-flex flex-wrap w-10">
                <span class="text-caption color-detail"> Quantidade: </span>
                <span class="w-full">
                  {{ ingresso.quantidade }}
                </span>
              </div>
              <div class="d-flex flex-wrap w-12">
                <span class="text-caption color-detail">
                  Vendas e Cortesias:
                </span>
                <span class="w-full">
                  {{ ingresso.quantidadeAdquirida }}
                </span>
              </div>
              <div>
                <v-btn
                  small
                  fab
                  color="primary"
                  class="mr-2 ma-1"
                  :disabled="
                    situacao == 3 ||
                    situacao == 4 ||
                    checkQuantidade ||
                    checkDate
                  "
                  @click="handleEdit(ingresso)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  small
                  fab
                  color="primary"
                  class="mr-2 ma-1"
                  :disabled="
                    situacao == 3 ||
                    situacao == 4 ||
                    ingresso.quantidadeAdquirida > 0 ||
                    checkEvent ||
                    checkDate
                  "
                  @click="deleteItem(ingresso)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn
                  v-if="!ingresso.isHabilitaCategoria"
                  small
                  fab
                  color="primary"
                  alt="habilitar ingresso"
                  class="mr-2 ma-1"
                  @click="habilitaItem(ingresso)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  v-if="ingresso.isHabilitaCategoria"
                  small
                  fab
                  color="primary"
                  class="mr-2 ma-1"
                  :disabled="checkDate"
                  @click="desabilitaItem(ingresso)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-card>
    <v-dialog v-model="exclusaoDialog" persistent max-width="370">
      <v-card>
        <v-card-title v-if="ingressoExclusao" class="text-h5">
          Deseja Excluir a Categoria <br />'{{
            ingressoExclusao.ingressoDescricao
          }}' ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="exclusaoDialog = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="excluirCategoria()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  TiposService,
  IngressoService,
  ProdutoService,
  InstalacaoService,
} from "../../../services";

export default {
  name: "moduloIngresso",
  props: {
    tipoIngresso: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    situacao: {
      type: Number,
      default: 1,
    },
    fotoMapa: {
      type: String,
      default: null,
    },
    finalDate: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      lote: "",
      area: "",
      especificacao: "",
      keyReset: 0,
      valorInicial: 0,
      valorDesconto: 0,
      precoInicial: "",
      taxa: 0,
      quantidade: "",
      ingressoId: null,
      ingressos: [],
      lotes: [],
      taxaMultiplicador: 0,
      quantidadeAdquirida: 0,
      areas: [],
      mapaUrl: "",
      edit: false,
      exclusaoDialog: false,
      ingressoExclusao: null,
      habilitaCategoria: false,
    };
  },

  mounted() {
    this.getTickets();
  },

  created() {
    this.getTickets();
    this.mapaUrl = this.fotoMapa;
    this.getLotes();
    this.getAreas();
    this.getTaxaMultiplicador();
  },
  watch: {
    valorInicial() {
      this.taxa =
        (this.valorInicial - this.valorDesconto) * this.taxaMultiplicador;
    },
    valorDesconto() {
      this.taxa =
        (this.valorInicial - this.valorDesconto) * this.taxaMultiplicador;
    },
    id() {
      this.getTickets();
    },
  },
  computed: {
    checkQuantidade() {
      return this.quantidade < this.quantidadeAdquirida ? true : false;
    },
    checkDate() {
      return new Date() > this.finalDate ? true : false;
    },
    hasLote() {
      return this.tipoIngresso === 3 || this.tipoIngresso === 4 ? true : false;
    },
    hasArea() {
      return this.tipoIngresso === 2 || this.tipoIngresso === 4 ? true : false;
    },
  },
  methods: {
    handleClose() {
      this.clean();
      this.$emit("onClose");
    },
    getTaxaMultiplicador() {
      InstalacaoService.getAtual()
        .then((response) => {
          this.taxaMultiplicador = response.data.percTaxaConveniencia / 100;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },

    habilitaItem(ingresso) {
      this.loading = true;
      let payload = {
        areaId: ingresso.areaId ? ingresso.areaId : 0,
        produtoId: ingresso.produtoId,
        id: ingresso.id,
        ingressoDescricao: ingresso.ingressoDescricao,
        loteId: ingresso.loteId ? ingresso.loteId : 0,
        quantidade: ingresso.quantidade,
        valor: ingresso.valor,
        valorDesconto: ingresso.valorDesconto,
        valorTaxaConveniencia: ingresso.valorTaxaConveniencia,
        valorTotal: ingresso.valorTotal,
        isHabilitaCategoria: true,
      };
      this.ingressos = this.ingressos.filter((i) => i.id !== ingresso.id);
      this.ingressos.push(payload);
      IngressoService.cadastrarItemIngresso(this.ingressos)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.getTickets();
          this.clean();
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    desabilitaItem(ingresso) {
      this.loading = true;
      let payload = {
        areaId: ingresso.areaId ? ingresso.areaId : 0,
        produtoId: ingresso.produtoId,
        id: ingresso.id,
        ingressoDescricao: ingresso.ingressoDescricao,
        loteId: ingresso.loteId ? ingresso.loteId : 0,
        quantidade: ingresso.quantidade,
        valor: ingresso.valor,
        valorDesconto: ingresso.valorDesconto,
        valorTaxaConveniencia: ingresso.valorTaxaConveniencia,
        valorTotal: ingresso.valorTotal,
        isHabilitaCategoria: false,
      };
      this.ingressos = this.ingressos.filter((i) => i.id !== ingresso.id);
      this.ingressos.push(payload);
      IngressoService.cadastrarItemIngresso(this.ingressos)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.getTickets();
          this.clean();
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clean() {
      this.edit = false;
      this.especificacao = null;
      this.ingressoId = null;
      this.valorInicial = 0;
      this.valorDesconto = 0;
      this.quantidadeAdquirida = 0;
      this.quantidade = null;
      if (this.area) {
        this.area = null;
      }
      if (this.lote) {
        this.lote = null;
      }
    },

    handleEdit(ingresso) {
      this.edit = true;
      this.especificacao = ingresso.ingressoDescricao;
      this.ingressoId = ingresso.id;
      this.valorInicial = ingresso.valorTotal;
      this.valorDesconto = ingresso.valorDesconto;
      this.quantidade = ingresso.quantidade;
      this.quantidadeAdquirida = ingresso.quantidadeAdquirida;
      this.area = ingresso.areaId;
      this.lote = ingresso.loteId;
    },

    salvarIngresso() {
      if (!this.quantidade || !this.especificacao) {
        return this.$root.showErro("Insira os dados da categoria");
      }

      if (this.quantidade == 0) {
        return this.$root.showErro("Insira uma quantidade");
      }

      if (
        this.valorDesconto &&
        this.valorDesconto > 1 &&
        parseFloat(this.valorDesconto) * 100 >=
          parseFloat(this.valorInicial) * 100
      ) {
        return this.$root.showErro(
          "O Valor do Desconto não pode ser maior que o valor do produto"
        );
      }

      let ingresso = {
        areaId: this.area ? this.area : 0,
        produtoId: this.id,
        ...(this.ingressoId && { id: this.ingressoId }),
        ingressoDescricao: this.especificacao,
        loteId: this.lote,
        quantidade: this.quantidade,
        ...(this.mapaUrl && {
          imagemMapa: {
            isMapa: true,
            isCapa: false,
            midiaUrl: this.mapaUrl,
            produtoId: this.id,
            tpMidia: "IMAGEM",
          },
        }),
        quantidadeAdquirida: 0,
        valor: this.valorInicial - this.valorDesconto,
        valorDesconto: this.valorDesconto,
        valorTaxaConveniencia: this.taxa,
        valorTotal: this.valorInicial,
        habilitaCategoria: this.habilitaCategoria,
      };
      if (
        (this.tipoIngresso === 2 || this.tipoIngresso === 4) &&
        !ingresso.areaId
      ) {
        return this.$root.showErro("Insira uma área");
      }
      if (
        (this.tipoIngresso === 3 || this.tipoIngresso === 4) &&
        !ingresso.loteId
      ) {
        return this.$root.showErro("Insira um lote");
      }
      if (ingresso.valor == 0 || !ingresso.valor) {
        return this.$root.showErro("Insira um valor válido");
      }
      if (!ingresso.quantidade || ingresso.quantidade == "") {
        return this.$root.showErro("Insira uma quantidade válida");
      }
      if (!ingresso.ingressoDescricao || ingresso.ingressoDescricao == "") {
        return this.$root.showErro("Insira uma especificação");
      }
      this.loading = true;
      this.ingressos.push(ingresso);

      IngressoService.cadastrarItemIngresso(this.ingressos)
        .then((response) => {
          console.log(response);
          this.getTickets();
          this.clean();
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
            this.ingressos = this.ingressos.filter((f) => f.id !== ingresso.id);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTickets() {
      this.loading = true;
      IngressoService.getEventoIngresso(this.id)
        .then((response) => {
          this.ingressos = response.data;
          this.mapaUrl = response.data[0]?.imagemMapa?.midiaUrl;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLotes() {
      TiposService.getLoteIngresso()
        .then((response) => {
          this.lotes = response.data.map((m) => ({
            text: m.descricao,
            value: m.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
    deleteItem(ingresso) {
      this.ingressoExclusao = ingresso;
      this.exclusaoDialog = true;
    },
    excluirCategoria() {
      this.loading = true;
      IngressoService.deleteItem(this.ingressoExclusao.id)
        .then((response) => {
          console.log(response);
          this.getTickets();
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loading = false;
          this.exclusaoDialog = false;
        });
    },
    handleUpload(type, event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.formData.append("tpMidia", "IMAGEM");
        this.loadingMidia = true;
        ProdutoService.uploadMidia(this.formData)
          .then((response) => {
            this.mapaUrl = response.data.midiaUrl;
            this.capaTpMidia = response.data.tpMidia;
            this.capaId = response.data.id;
            let payload = {
              isCapa: false,
              isMapa: true,
              midiaUrl: this.mapaUrl,
              produtoId: this.id,
              tpMidia: "IMAGEM",
            };
            IngressoService.uploadMidia(payload)
              .then((response) => {
                console.log(response);
              })
              .catch((erro) => {
                this.$root.showErro(erro.data);
              });
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
    deleteMapa() {
      IngressoService.deleteMidia(this.id)
        .then((response) => {
          console.log(response);
          this.mapaUrl = "";
        })
        .catch((erro) => {
          this.$root.showErro(erro.data);
        });
    },
    getAreas() {
      TiposService.getAreaIngresso()
        .then((response) => {
          this.areas = response.data.map((m) => ({
            text: m.descricao,
            value: m.id,
          }));
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        });
    },
  },
};
</script>

<style>
.w-15 {
  width: 15%;
}
.w-8 {
  width: 8%;
}
.w-10 {
  width: 10%;
}
</style>