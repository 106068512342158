<template>
  <v-container pa-5 fluid>
    <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
        <v-toolbar-title>
        <span class="headline"> Lista de Categorias</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
    
        <v-icon large @click="closeDialog">mdi-close</v-icon>
    </v-toolbar>    
          
    <v-card>
      
      <v-toolbar flat color="white">
        <v-toolbar-title>Categorias</v-toolbar-title>
        <v-divider class="mx-2" inset vertical />
        
        <v-text-field
          v-model="search"
          hide-details
          placeholder="Descrição"
          solo
          dense
          clearable
        />

        <v-spacer />
        
        <!-- <v-btn color="primary" class="mb-2" outlined @click="novaCategoria">
          Nova Categoria
        </v-btn> -->
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="categorias"
        :loading="loading"
        :search="search"
        class="elevation-1"
        loading-text="Carregando..."
        hide-default-footer
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span
            class="subheading font-weight-light text--darken-3"
            v-text="header.text"
          />
        </template>

        <template v-slot:body="{ items }" ma-5>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
             
              <td>{{ item.descricao }}</td>
              <td class="text-right">
                <v-icon
                  class="mr-2"
                  title="Editar categoria"
                  @click="editarCategoria(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  color="primary"
                  title="Excluir categoria"
                  @click="openDialogDelete(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="dialogDelete">
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="500px">
          <v-card>
            <v-form
              ref="formDelete"
              v-model="valid"
              lazy-validation
              @submit.prevent="deleteCategoria"
            >
              <v-card-title>
                <span class="headline">Excluir Categoria</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <span class="title">
                        Deseja realmente excluir essa categoria?
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="categoriaExcluir"
                        label="Nome da categoria que será excluída*"
                        :rules="[rules.required]"
                        :hint="categoria.descricao"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="btnCancelar"
                  text
                  @click="() => (dialogDelete = false)"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Excluir</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="dialog">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
              <v-toolbar-title>
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="salvarCategoria"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="categoria.descricao"
                        label="Descrição *"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-container>
                  <v-row>
                    <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="categorias"
                :loading="loading"
                :search="search"
                class="elevation-1"
                loading-text="Carregando..."
                hide-default-footer
              >
                <template slot="headerCell" slot-scope="{ header }">
                  <span
                    class="subheading font-weight-light text--darken-3"
                    v-text="header.text"
                  />
                </template>

                <template v-slot:body="{ items }" ma-5>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                    
                      <td>{{ item.descricao }}</td>
                      <td class="text-right">
                        <v-icon
                          class="mr-2"
                          title="Editar categoria"
                          @click="editarCategoria(item)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          color="primary"
                          title="Excluir categoria"
                          @click="openDialogDelete(item)"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              
            </v-col>
                  </v-row>
                </v-container>
                
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialog = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Salvar</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>


    <template v-if="dialogPreco">
      <v-row justify="center">
        <v-dialog v-model="dialogPreco" persistent max-width="700px">
          <v-card>
            <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
              <v-toolbar-title>
                <span class="headline">{{ categoria.descricao }} </span>
              </v-toolbar-title>
              <!-- <v-spacer></v-spacer>
              <v-btn color="primary" class="mb-2" outlined @click="novaCategoria">
                Novo
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn
              color="primary"
              fab
              small
              @click="novaCategoriaItem(categoria)"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
              <!-- <v-icon large @click="dialogPreco = false">mdi-close</v-icon> -->
            </v-toolbar>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="salvarCategoria"
            >

              <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headersPreco"
                        :items="categoriasPrecos"
                        :loading="loading"
                        :search="search"
                        class="elevation-1"
                        loading-text="Carregando..."
                        hide-default-footer
                      >
                        <template slot="headerCell" slot-scope="{ header }">
                          <span
                            class="subheading font-weight-light text--darken-3"
                            v-text="header.text"
                          />
                        </template>

                        <template v-slot:body="{ items }" ma-5>
                          <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                            
                              <td>{{ item.descricao }}</td>
                              <td>{{ item.sigla }}</td>
                              <td>{{ asMoney(item.valor) }}</td>
                              <td class="text-right">
                                <v-icon
                                  class="mr-2"
                                  title="Editar categoria"
                                  @click="editarCategoriaItem(item)"
                                  >mdi-pencil</v-icon
                                >
                                <v-icon
                                  color="primary"
                                  title="Excluir categoria"
                                  @click="deleteCategoriaItem(item)"
                                  >mdi-delete</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialogPreco = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  @click="dialogPreco = false"
                >
                  <span style="color: white;">Salvar</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="dialogCategoriaItem">
      <v-row justify="center">
        <v-dialog v-model="dialogCategoriaItem" persistent max-width="500px">
          <v-card>
            <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
              <v-toolbar-title>
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large @click="dialogCategoriaItem = false">mdi-close</v-icon>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="salvarCategoriaItem"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="categoriaItem.descricao"
                        label="Tipo de Ingresso"
                        :rules="[rules.required]"
                      ></v-text-field>

                      <v-text-field
                        v-model="categoriaItem.sigla"
                        label="Sigla"
                        
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                        <vuetify-money
                          v-model="categoriaItem.valor"
                          label="Valor"
                          class="pr-2 w-12"
                        />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialogCategoriaItem = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Salvar</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import { CategoriaService, LocaisCategoriaService } from "../../../services";
import { Role } from "@/constants";

export default {

  props: {
        item: {
          type: Number,
          required: true
        },

        edit: {
            type: Boolean,
            default: false
        }

    },

  data: () => ({
    itemId: null,
    valid: false,
    search: "",
    categorias: [],
    dialog: false,
    dialogSenha: false,
    dialogDelete: false,
    dialogPreco: false,
    filtros: {
      descricao: ""
    },
    options: {},
    headers: [
      { text: "Descrição", value: "descricao" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    headersPreco: [
      { text: "Tipo de Ingresso", value: "descricao" },
      { text: "Sigla", value: "sigla" },
      { text: "Valor", value: "valor" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    editedIndex: -1,
    categoria: {
      descricao: "",
      id: 0
    },
    categoriaItem: {
        id: 0,
        localCategoriaId: "",
        descricao: "",
        sigla: "",
        valor: 0,
    },
    dialogCategoriaItem: false,
    rules: {
      required: value => !!value || "Campo obrigatório.",
      confirmDelete: value =>
        (!!value && this.categoria.descricao === value) ||
        "A categoria precisa ser igual!"
    },
    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false,
    categoriaExcluir: ""
  }),

  computed: {
    formTitle() {
      return this.categoria.id ? "Alterar Categoria" : "Nova Categoria";
    }
  },


  created(){
    if(this.isMobile){
      this.$router.push('/check-in')
    }
    
    if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
      this.$router.push('/check-in')
    }

  },
  mounted() {
    this.itemId = this.item;
    this.reload();
    console.log(this.itemId)
  },

  methods: {
    reload() {
      this.getAll();
    },

    
    novaCategoria() {
      this.categoria = {
        descricao: "",
        id: 0
      };
      this.dialog = true;
    },

    editarCategoria(item) {
      // this.categoria = { ...item };
      // this.dialogPreco = true;
      LocaisCategoriaService.getLocalCategoriaPreco(item.id)
      .then(response => {
          this.categoriasPrecos = response.data;
          console.log(this.categoriasPrecos, "preco");
          
          this.categoria = { ...item };
          this.dialogPreco = true;
        })
        .finally(() => {
          this.loading = false;
      });
    },

    
    closeDialog() {
      this.$emit('onClose')
    },

    openDialogDelete(item) {
      this.categoria = item;
      this.categoriaExcluir = "";
      this.dialogDelete = true;
    },

    deleteCategoria() {
      if (this.$refs.formDelete.validate()) {
        if (this.categoria.descricao === this.categoriaExcluir) {
          this.loadingSalvar = true;
          CategoriaService.delete(this.categoria.id)
            .then(response => {
              this.$root.showSucesso(response.data);
            })
            .catch(error => {
              if (error.status != 401) {
                this.$root.showErro(error.data);
              }
            })
            .finally(() => {
              this.loadingSalvar = false;
              this.dialogDelete = false;
              this.reload();
            });
        } else {
          this.$root.showErro(
            `O nome da categoria deve ser: <b>${this.categoria.descricao}</b>`
          );
        }
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },

    salvarCategoria() {
      this.loadingSalvar = true;
      console.log(this.categoria)
      let payload = {
        descricao: this.categoria.descricao,
        localId: this.itemId
      };

      LocaisCategoriaService.cadastroLocalCategoria(payload)
        .then(response => {
          this.$root.showSucesso("Operação realizada com sucesso");
          this.categoria = response.data;
          this.closeDialog();
          this.reload();
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },

    editarCategoriaItem(item) {
      this.categoriaItem = { 
        descricao: item.descricao,
        localCategoriaId: item.localCategoriaId,
        sigla: item.sigla,
        valor: item.valor,
        id: item.id
      };
      console.log(item, "edit item")
      this.dialogCategoriaItem = true;

    },

    novaCategoriaItem(item) {
      this.categoriaItem = { 
        localCategoriaId: item.id,
        valor: 0
      };
      console.log(item, "edit item")
      this.dialogCategoriaItem = true; 
    },

   deleteCategoriaItem(item){
    this.loadingSalvar = true;
      LocaisCategoriaService.deletePreco(item.id)
        .then(response => {
          this.$root.showSucesso("Operação realizada com sucesso");
          this.dialogCategoriaItem = false;
          this.reload();
          console.log(response.data, "log")
          this.updateCategoria(item.localCategoriaId);
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    getAll() {
      this.loading = true;
      console.log(this.itemId, "item pass");
      LocaisCategoriaService.getLocalCategoriaDetalhe(this.itemId)
        .then(response => {
          this.categorias = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
