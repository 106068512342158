<template>
  <div>
    <v-card>
      <v-toolbar style="margin-bottom: 5px" flat>
        <v-toolbar-title>
          <span class="headline"> Taxa Conveniência </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose($event)">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />
      <v-row v-if="loading" class="justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <v-col v-else cols="12" md="12" class="my-3 px-4 d-flex">
        <div style="width: 100%">
          <v-row class="d-flex px-2">

            <vuetify-money
              v-model="taxaMultiplicador"
              disabled
              label="Taxa de Conveniência"
              class="pr-2 w-12"
            />
            <vuetify-money
              v-model="taxa"
              label="Nova Taxa"
              class="pr-2 w-12"
            />
            
            <v-col cols="12" md="12" class="my-1 px-4 d-flex justify-end">

            <v-btn
              color="primary"
              
              @click="salvarIngresso()"
            >
              Salvar
            </v-btn>
          </v-col>
           
           
          </v-row>
          <v-divider />
          
        </div>
      </v-col>
    </v-card>
   
  </div>
</template>

<script>
import {
  IngressoService,
} from "../../../services";

export default {
  name: "moduloIngressoTaxa",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      keyReset: 0,
      taxa: 0,
      
      exclusaoDialog: false,
      ingressoExclusao: null,
      
      taxaMultiplicador: 0,
      produto: "",
    };
  },

  mounted() {
    // this.getTickets();
  },

  created() {   
    this.getTaxaMultiplicador();
  },
  watch: {
    id() {
      this.getTickets();
    },
  },

  methods: {
    handleClose() {
      this.$emit("onClose");
    },

    getTaxaMultiplicador() {
      console.log("id do produto = " + this.id)
      IngressoService.getTaxaConveniencia(this.id)
      .then((response) => {
          this.taxaMultiplicador = response.data.taxa;
          this.produto = response.data.produto;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
      });

    },

    salvarIngresso() {
     
      if (this.taxa  && this.taxa < 10 )  {
        return this.$root.showErro("O Valor da Taxa deve ser preenchido maior que 10%! ");
      }

      let ingresso = {
        produtoId: this.id,
        taxa: this.taxa,
      };
     
      this.loading = true;

      IngressoService.setTaxaConveniencia(ingresso)
        .then((response) => {
          console.log(response);
          this.taxaMultiplicador = ingresso.taxa;
          this.taxa = 0;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTickets() {
      this.loading = true;
      IngressoService.getTaxaConveniencia(this.id)
        .then((response) => {
          this.taxaMultiplicador  = response.data.taxa;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>

<style>
.w-15 {
  width: 15%;
}
.w-8 {
  width: 8%;
}
.w-10 {
  width: 10%;
}
</style>